import { FC, useEffect, Dispatch, SetStateAction } from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { FieldValues, UseFormResetField } from 'react-hook-form';

import { ApiError } from '@dc/client/ApiError';
import { useFormatter, useTranslation } from '@i18n';
import { Customers_Address_AddressModel } from '@monorepo-types/dc';
import type { NotificationField } from '@sitecore/types/AddressFinder';
import { Box, InputSelect, InputText, Stack, Text, VisuallyHidden } from '@sparky';
import { SpinnerIcon } from '@sparky/icons';

import { maskPostalcode, maskHouseNumber } from './utils/maskInput';
import RichText from '../RichText/RichText';
import { TrackedNotificationBox } from '../TrackedNotificationBox/TrackedNotificationBox';

interface CustomFormField {
  value: {
    hint: string;
    label: string;
    placeholder?: string;
    requiredMessage: string;
    validationMessage: string;
  };
}

export interface Error {
  status?: number;
}

interface Labels {
  houseNumber?: CustomFormField;
  houseNumberSuffix?: CustomFormField;
  postalCode?: CustomFormField;
  notFoundErrorNotification?: NotificationField;
  serverErrorNotification?: NotificationField;
}

interface Props {
  addressData: Customers_Address_AddressModel;
  apiError: ApiError;
  currentSelectedHouseNumberSuffix?: string | undefined;
  customLabels?: Labels;
  houseNumberSuffixOptions: {
    label: string;
    value: string;
  }[];
  isSuffixRequired: boolean;
  isValidating: boolean;
  setErrorData?: Dispatch<SetStateAction<ApiError | undefined>>;
  customErrorNotification?: React.ReactElement;
  hasAutocomplete: boolean;
  resetField: UseFormResetField<FieldValues>;
  addressGroupLabel: string;
}

export const AddressFinderView: FC<Props> = ({
  addressData,
  apiError,
  currentSelectedHouseNumberSuffix,
  customLabels,
  houseNumberSuffixOptions,
  isSuffixRequired,
  isValidating,
  setErrorData,
  customErrorNotification,
  hasAutocomplete,
  resetField,
  addressGroupLabel,
}) => {
  const { t } = useTranslation();
  const { address: addressFormatter } = useFormatter();
  const {
    control,
    formState: { errors: formError },
    register,
  } = useFormContext();

  let errorMessage: NotificationField;

  switch (apiError?.status) {
    case 500:
      errorMessage = customLabels?.serverErrorNotification || {
        value: {
          content: t(`validationMessage.value.serverError.text`),
          title: t(`validationMessage.value.serverError.title`),
          variant: 'error',
        },
      };
      break;
    default:
      errorMessage = customLabels?.notFoundErrorNotification || {
        value: {
          content: t(`validationMessage.value.notFoundError.text`),
          title: t(`validationMessage.value.notFoundError.title`),
          variant: 'error',
        },
      };
      break;
  }

  useEffect(() => {
    if (!setErrorData) return;

    if (apiError?.status) {
      setErrorData(apiError);
    } else {
      setErrorData(undefined);
    }
  }, [apiError]);

  const FoundAddress: FC<{ isSuffixRequired: boolean }> = ({ isSuffixRequired }) => {
    const { street, city } = addressData;
    const hasError =
      Object.keys(formError).length > 0 ||
      !!apiError ||
      (!street && !city) ||
      (isSuffixRequired && !currentSelectedHouseNumberSuffix);
    if (hasError) return null;

    const Address = (
      <Box paddingTop="3">
        <Text size="BodyS">{addressFormatter.long(addressData)}</Text>
      </Box>
    );

    return Address;
  };

  return (
    <>
      <Box>
        <Stack as="fieldset" direction={{ initial: 'column', md: 'row' }} gap="3">
          <VisuallyHidden>
            <legend>{addressGroupLabel}</legend>
          </VisuallyHidden>
          <Stack.Item grow>
            <Controller
              control={control}
              name="postalCode"
              render={({ field }) => (
                <InputText
                  error={
                    formError.postalCode?.message &&
                    (customLabels?.postalCode?.value.requiredMessage || t('postalCode.value.requiredMessage'))
                  }
                  hint={customLabels?.postalCode?.value.hint || t('postalCode.value.hint')}
                  label={customLabels?.postalCode?.value.label || t('postalCode.value.label')}
                  name={field.name}
                  onBlur={field.onBlur}
                  onChange={event => {
                    resetField('houseNumberSuffix', { defaultValue: '' });
                    field.onChange(maskPostalcode(event));
                  }}
                  placeholder={customLabels?.postalCode?.value.placeholder || t('postalCode.value.placeholder')}
                  value={field.value}
                  autoComplete={hasAutocomplete ? 'postal-code' : 'off'}
                />
              )}
            />
          </Stack.Item>
          <Stack.Item grow>
            <Stack direction="row" gap="3">
              <Stack.Item grow>
                <Controller
                  control={control}
                  name="houseNumber"
                  render={({ field }) => (
                    <InputText
                      error={
                        formError.houseNumber?.message &&
                        (customLabels?.houseNumber?.value.requiredMessage || t('houseNumber.value.requiredMessage'))
                      }
                      hint={customLabels?.houseNumber?.value.hint || t('houseNumber.value.hint')}
                      label={customLabels?.houseNumber?.value.label || t('houseNumber.value.label')}
                      name={field.name}
                      placeholder={customLabels?.houseNumber?.value.placeholder || t('houseNumber.value.placeholder')}
                      onChange={event => {
                        resetField('houseNumberSuffix', { defaultValue: '' });
                        field.onChange(maskHouseNumber(event));
                      }}
                      value={field.value}
                      onBlur={field.onBlur}
                      autoComplete={hasAutocomplete ? undefined : 'off'}
                    />
                  )}
                />
              </Stack.Item>
              <Stack.Item grow>
                <InputSelect
                  {...register('houseNumberSuffix')}
                  data-testid="houseNumberSuffix"
                  error={
                    formError.houseNumberSuffix?.message &&
                    (customLabels?.houseNumberSuffix?.value.requiredMessage ||
                      t('houseNumberSuffix.value.requiredMessage'))
                  }
                  hint={customLabels?.houseNumberSuffix?.value.hint || t('houseNumberSuffix.value.hint')}
                  isDisabled={!houseNumberSuffixOptions.length}
                  label={customLabels?.houseNumberSuffix?.value.label || t('houseNumberSuffix.value.label') || ''}
                  options={houseNumberSuffixOptions}
                  placeholder={' '}
                  autoComplete={hasAutocomplete ? undefined : 'off'}
                />
              </Stack.Item>
            </Stack>
          </Stack.Item>
        </Stack>
      </Box>

      {apiError?.status && !customErrorNotification && (
        <Box paddingTop="3">
          <TrackedNotificationBox
            emphasis="low"
            isAlert
            text={errorMessage?.value?.content && <RichText html={errorMessage?.value?.content} />}
            title={errorMessage?.value?.title}
            variant="error"
          />
        </Box>
      )}

      {apiError?.status && customErrorNotification && <Box paddingTop="3">{customErrorNotification}</Box>}

      {isValidating ? (
        <Box paddingTop="3">
          <SpinnerIcon size="medium" />
        </Box>
      ) : (
        <FoundAddress isSuffixRequired={isSuffixRequired} />
      )}
    </>
  );
};
