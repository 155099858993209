import { createDCHook, collapseDataFromCall } from '../client';
import {
  getAddressPublic,
  getAddressPublicV2,
  getAddressPublicV3,
  getAddressBusinessCheck,
} from '../services/AddressPublicService';

export const useAddressPublicGetAddressPublic = createDCHook(
  'getAddressPublic',
  collapseDataFromCall(getAddressPublic),
  { injectables: ['label', 'businessUnit'], flattenData: true },
);

export const useAddressPublicGetAddressPublicV2 = createDCHook(
  'getAddressPublicV2',
  collapseDataFromCall(getAddressPublicV2),
  { injectables: ['label', 'businessUnit'], flattenData: true },
);

export const useAddressPublicGetAddressPublicV3 = createDCHook(
  'getAddressPublicV3',
  collapseDataFromCall(getAddressPublicV3),
  { injectables: ['label', 'businessUnit'], flattenData: true },
);

export const useAddressPublicGetAddressBusinessCheck = createDCHook(
  'getAddressBusinessCheck',
  collapseDataFromCall(getAddressBusinessCheck),
  { injectables: ['label', 'businessUnit'], flattenData: true },
);
